import React from "react";
import styled from "styled-components";

function Appairel({ avatar, link, name, desig, quote, place }) {
  return (
    <Container>
      <div className="childi sing_boxi" style={{ maxWidth: "400px" }}>
        <div className="line_1">
          <img src={avatar} alt="" />
        </div>
        <div className="line_2">
          <blockquote>{quote}</blockquote>
        </div>
        <div className="line_3">
          <h1>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          </h1>
          <h2>{desig}</h2>
          <h3>{place}</h3>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .childi {
    &.sing_boxi {
      /* box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff; */
      box-shadow: 0 0 10px #838181;
      border-radius: 10px;
      max-width: 600px;
      /* padding: 20px; */
      overflow: hidden;
      margin: 30px auto;
      height: 100%;
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      @media (max-width: 548px) {
        max-width: 330px;
        min-width: 270px;
        margin: 20px;
      }
      @media (max-width: 335px) {
        width: 100vw;
      }
      &:focus {
        text-decoration: overline;
      }
      .line_1 {
        display: flex;
        flex-wrap: wrap;
        background-color: aliceblue;
        height: 100px;
        img {
          width: 100px;
          height: 100px;
          margin: auto;
          margin-bottom: -50px;
          border-radius: 50%;
          background: #0b5750;
        }
      }
      .line_2 {
        padding: 20px 15px 15px;
        margin-top: 30px;
        blockquote {
          font-size: 16px;
          font-style: italic;
          font-family: "Times New Roman", Times, serif;
          line-height: 20px;
          font-family: "Shippori Mincho", serif;
        }
      }
      .line_3 {
        font-family: "Shippori Mincho", serif;
        text-align: center;
        padding: 0 15px 15px;
        font-weight: 800 !important;
        h1 {
          line-height: 15px;
          a {
            font-size: 18px;
            color: rgba(0, 0, 0, 10);
            text-decoration: none;
          }
        }
        h2 {
          font-size: 16px !important;
          line-height: 25px;
          font-weight: 700;
        }
        h3 {
          font-size: 14px !important;
          line-height: 15px;
        }
      }

      blockquote {
        @media (max-width: 548px) {
          margin-top: 8px;
          padding: 0 5px;
          height: 100px;
          overflow-x: hidden;
          overflow-y: scroll;
          position: relative;
          &::-webkit-scrollbar {
            width: 4px;
            height: 10px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #0b5750;
            border-radius: 10px;
            padding: 5px;
            border: 3px solid #0b5750;
          }
        }
      }
      blockquote::before {
        content: "“";
        font-style: normal;
        font-size: 2rem !important;
        color: #0b5750;
        margin-bottom: -4px;
        height: 6px;
      }
      blockquote::after {
        content: "”";
        position: absolute;
        margin-top: 2px;
        font-style: normal;
        font-size: 2rem !important;
        color: #0b5750;
      }
    }
  }
`;

export default Appairel;
