import React from "react";

import CertificateBox from "./CertificateBox";

function CertificateBlock(props) {
  return (
    <>
      {props.data.map((j) => (
        <CertificateBox
          key={j.name}
          name={j.name}
          imgSrc={j.imgSrc}
          imgSrci={j.imgSrci}
        />
      ))}
    </>
  );
}

export default CertificateBlock;
