import React, { useState } from "react";

// component imports
// import Header from "../Components/Header";
import CertificateBlock from "../Components/CertificateBlock";
import MetaData from "../Components/MetaData";

// data imports
import {
  certifiedCourses,
  totalNumOfTakenCourse,
  auditCourses,
} from "../data/summary";
import {
  CCAcer,
  iTSupport,
  languLearn,
  personalDev,
  proCert,
  webReleted,
} from "../data/certificateData";

// animation imports
import Fade from "react-reveal/Fade";

function Certificates() {
  const [learnStat, setLearnStat] = useState("know__more");

  return (
    <>
      <MetaData
        canon="https://ah-jim.web.app/about/certificates/"
        title="Certificates |"
      />
      {/* <Header
        clsh="nav__items"
        clsa="nav__items active"
        clsp="nav__items"
        clsc="nav__items"
      /> */}

      {/* know how to see certificate */}

      <section className={learnStat}>
        <Fade top>
          <div className="learn">
            <h6>Let's Know before go.</h6>
            <p>
              To see certificates just click over the certificate, and to hide
              click anywhere on the screen.
            </p>
            <div
              role="button"
              tabIndex="2"
              className="hide"
              onClick={() => setLearnStat("know__more drown")}
            >
              Let's go
            </div>
          </div>
        </Fade>
      </section>

      <Fade>
        <main className="Certificates">
          {/* title */}

          <Fade top>
            <div className="title">
              <h1>Certificates</h1>
            </div>
          </Fade>

          {/* summary calculation */}

          <Fade bottom>
            <section className="sumOfQyalification">
              <div className="sumUPBox grid_parent">
                <div className="description">Courses I Took</div>
                <div className="numaric">{totalNumOfTakenCourse}</div>
              </div>
              <div className="sumUPBox grid_parent">
                <div className="description">Certified Courses</div>
                <div className="numaric">{certifiedCourses}</div>
              </div>
              <div className="sumUPBox grid_parent">
                <div className="description">Audit Courses</div>
                <div className="numaric">{auditCourses}</div>
              </div>
            </section>
          </Fade>

          <br />

          {/* Professional Certificates */}

          <section className="certifiacte_container grid_parent">
            <h2>Learning Paths or Professional Certificate</h2>

            <CertificateBlock data={proCert} />
          </section>

          <br />

          {/* Web Development */}

          <section className="certifiacte_container grid_parent">
            <h2>Web Development</h2>

            <CertificateBlock data={webReleted} />
          </section>

          <br />

          {/* IT Support */}
          <section className="certifiacte_container grid_parent">
            <h2>IT Support</h2>

            <CertificateBlock data={iTSupport} />
          </section>

          <br />

          {/* Language Learning */}
          <section className="certifiacte_container grid_parent">
            <h2>Language Learning</h2>

            <CertificateBlock data={languLearn} />
          </section>

          <br />

          {/* Personal Development */}
          <section className="certifiacte_container grid_parent">
            <h2>Personal Interest Course</h2>

            <CertificateBlock data={personalDev} />
          </section>

          <br />

          {/* Co-Curricular Activities */}
          <section className="certifiacte_container grid_parent">
            <h2>Co-Curricular Activities</h2>

            <CertificateBlock data={CCAcer} />
          </section>
        </main>
      </Fade>
    </>
  );
}

export default Certificates;
