import styled from "styled-components";

export const CompAbout = styled.main`
  margin-top: 86px;
  margin-bottom: 135px;
  background-color: #fff;
  @media (max-width: 480px) {
    margin-bottom: 150px;
  }
  .title {
    border-bottom: dotted #d4d4d2;
    h1 {
      text-align: center !important;
    }
    h2 {
      padding-bottom: 10px;
      color: #141412ce;
      text-align: center !important;

      a {
        font-family: "Caveat", cursive !important;
        color: #000;
        &:hover {
          color: #0b5750;
        }
      }
    }
  }
  .about_details {
    margin: 50px auto;
    padding: 50px 20px;
    @media (max-width: 768px) {
      padding: 40px 20px;
    }
    h1 {
      color: #000;
      margin: auto;
      border-bottom: #80807d dotted;
    }
    p {
      padding: 30px;
      @media (max-width: 768px) {
        padding: 30px 10px 0 10px;
      }
    }
  }
  .taw {
    padding: 30px 0 0;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: center;
    justify-content: center !important;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 548px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .container_b {
    max-width: 1000px;
  }
  .edi {
    &.a {
      border-radius: 16px;
      padding: 30px;
      box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #fff;
      max-width: 1200px;
      margin: auto;
      margin-top: 60px;
    }
  }
`;
