import React from "react";
// import Header from "../Components/Header";
import MetaData from "../Components/MetaData";
import ProjectBox from "../Components/ProjectBox";

import {
  projectDataRecent,
  projectDataWorks,
  projectDataDemos,
  projectDataPortfolios,
  projectDataProjects,
  projectDataFuns,
  frontendioProj,
} from "../data/ProjectData";

// animation importation
import Fade from "react-reveal/Fade";
import { Body, SmallBoxParent } from "../style/components";
import { CompProject } from "../style/page/project";

function Project() {
  return (
    <Body>
      <MetaData canon="https://ah-jim.web.app/project/" title="Project |" />

      {/* <Header
        clsh="nav__items"
        clsa="nav__items"
        clsp="nav__items active"
        clsc="nav__items"
      /> */}

      <Fade>
        <CompProject>
          {/* title */}

          <Fade top>
            <div className="title">
              <h1>Projects</h1>
            </div>
          </Fade>

          {/* recent Projects */}

          <Fade bottom>
            <h2 style={{ textAlign: "center", margin: "20px auto" }}>
              Recent Projects
            </h2>
          </Fade>
          <SmallBoxParent className="recentProject">
            <ProjectBox projectData={projectDataRecent} />
          </SmallBoxParent>

          <div className="container_aa project-page projects">
            {/* Work projects */}

            <h2>Works:</h2>

            <SmallBoxParent className="project con bo grid_parent small_box_parent">
              <ProjectBox projectData={projectDataWorks} />
            </SmallBoxParent>

            {/* clone or demo projects */}

            <h2>Clone Apps & Demos:</h2>
            <SmallBoxParent className="project con bo grid_parent small_box_parent">
              <ProjectBox projectData={projectDataDemos} />
            </SmallBoxParent>

            {/* Frontend montor works */}

            <h2>Challenges:</h2>
            <SmallBoxParent className="project con bo grid_parent small_box_parent">
              <ProjectBox projectData={frontendioProj} />
            </SmallBoxParent>

            {/* Portfolios */}

            <h2>Portfolios:</h2>
            <SmallBoxParent className="project con bo grid_parent small_box_parent">
              <ProjectBox projectData={projectDataPortfolios} />
            </SmallBoxParent>

            {/* Projects */}

            <h2>Projects:</h2>
            <SmallBoxParent className="project con bo grid_parent small_box_parent">
              <ProjectBox projectData={projectDataProjects} />
            </SmallBoxParent>

            {/* Fun project */}

            <h2>Fun:</h2>

            <SmallBoxParent className="project con bo grid_parent small_box_parent">
              <ProjectBox projectData={projectDataFuns} />
            </SmallBoxParent>
          </div>
        </CompProject>
      </Fade>
    </Body>
  );
}

export default Project;
