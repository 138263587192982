import React from "react";
import styled from "styled-components";

function CalBox({ name, num }) {
  return (
    <Container className="sumUPBox grid_parent">
      <div className="description">{name}</div>
      <div className="numaric">{num}</div>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  /* aspect-ratio: 1/1; */
  width: 120px;
  margin: auto 20px;
  @media (max-width: 548px) {
    margin: auto 10px;
  }
  .description {
    font-size: 15px;
    padding: 10px;
    background-color: #0b5750;
    color: #fff;
    text-align: center;
    @media (max-width: 548px) {
      font-size: 11px;
      padding: 10px 7px;
    }
  }
  .numaric {
    border: 2px solid #0b5750;
    padding: 20px;
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    color: #0b5750;
  }
`;

export default CalBox;
