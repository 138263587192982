import React from "react";
// import { Link } from "react-router-dom";

// data importation
import MetaData from "../Components/MetaData";
import eduData from "../data/EducationData";
import Education from "../Components/Education";
// import { frontEndSkills, itSSkill } from "../data/SkillData";

// component importation
// import Header from "../Components/Header";
import { SmallBox } from "../Components/SmallBox";
// import XsBox from "../Components/XsBox";

// media importation
import resume from "../media/PDFs/Akhlak Hossain Jim  Resume.pdf";

// animation importation
import Fade from "react-reveal/Fade";
import wrokHistoryData from "../data/workHistory";
import { Body } from "../style/components";
import { CompAbout } from "../style/page/about";

function About() {
  return (
    <Body>
      <MetaData canon="http://ah-jim.web.app/about/" title="About | " />

      {/* <Header
        clsh="nav__items"
        clsa="nav__items active"
        clsp="nav__items"
        clsc="nav__items"
      /> */}

      <Fade>
        <CompAbout className="About">
          {/* Titel */}

          <div className="title">
            <Fade bottom>
              <h1>About</h1>
            </Fade>
            <Fade bottom>
              <h2>
                Jump To :&nbsp;
                <a href="#asADev">As A Developer</a>&nbsp;|&nbsp;
                <a href="#bigData">Big Data</a>
                <br />
                View:&nbsp;
                <a href={resume} target="_blank" rel="noopener noreferrer">
                  Resume
                </a>
                {/* &nbsp;|&nbsp;
                <Link to="/about/certificates/">Certificates</Link> */}
              </h2>
            </Fade>
          </div>

          {/* As a wev developer details */}

          <Fade>
            <div id="asADev" className="container_b about_details">
              <h1>As A Developer:</h1>
              <p>
                Hi, I'm a front-end developer and IT Support Specialist, from
                Bangladesh. I create strenuous websites that are fast, easy to
                use and build with best practices & utmost dedication. And love
                to work with hardware and help people with their IT devices.
                <br />
                <br />
                I started creating and developing websites since I was 16. I
                work to make websites more responsive, interactive, simple,
                beautiful, and user-friendly, and easily readable for other
                developers. And work with hardware in the background to ensure
                everything is working properly.
                <br />
                <br />
                I’m hungry to learn new things and from every single setback. So
                I look forward to learning from all people and practice it to
                better use.
              </p>
            </div>
          </Fade>

          {/* why me elaboration */}

          <Fade>
            <div className="container_b about_details">
              <h1>Why me?</h1>
              <p>
                I love to code and build websites for good peoples.
                <br />
                <br />
                I started creating and developing websites since I was 16. I
                work to make websites more responsive, interactive, simple,
                beautiful, user-friendly, and easily readable for other
                developers. & at the age of 17, I found my interest in hardware,
                IT infrastructure and things.
                <br />
                <br />
                I’d love to hear from you and work with you. Wish that it will
                be a fulfilling journey for all of us.
              </p>
            </div>
          </Fade>

          {/* work history section */}

          <div className="container_b about_details">
            <h1>Professional History</h1>
            <div className="taw" style={{ display: "grid" }}>
              {wrokHistoryData.map((w) => (
                <Fade right>
                  <SmallBox
                    key={w.title}
                    title={w.title}
                    type={w.type}
                    role={w.role}
                    address={w.address}
                    date={w.date}
                    listcon={w.listcon}
                    ili={w.ili}
                    ilii={w.ilii}
                    iliii={w.iliii}
                    iliv={w.iliv}
                  />
                </Fade>
              ))}
            </div>
          </div>

          {/* Key skills */}

          {/* <div className="container_b about_details">
            <h1>Summary Of Qualification</h1>
            <div className="container_a edi">
              <h2>Front-end:</h2>
              <div className="grid_parent small_box_parent">
                {frontEndSkills.map((e) => (
                  <Fade right>
                    <XsBox key={e} name={e} />
                  </Fade>
                ))}
              </div>
            </div>
            <div className="container_a edi">
              <h2>IT Support Specialis:</h2>
              <div className="grid_parent small_box_parent">
                {itSSkill.map((e) => (
                  <Fade right>
                    <XsBox key={e} name={e} />
                  </Fade>
                ))}
              </div>
            </div>
          </div> 

          <hr />
          */}

          {/* Elaboration about my self(big) */}

          <Fade>
            <div id="bigData" className="container_b about_details">
              <h1>Big History:</h1>
              <p>
                This is Jim, who passionate about technology, traveling,
                entrepreneurship and learning new things, And also have a vast
                interest in the education sector & love to introduce myself as a
                creative learner. As every student, I have the same basic
                problems of the institutional educational system of our country
                and I know I can't fix it at that time, and still now, so I
                changed myself and my learning style. Currently, I'm working to
                connect students to learn in their own creative way together and
                teach them to learn and listen. If the project I'm working for
                can bring people together to make a creative learning community,
                help them accomplish something, or make them feel comfortable to
                learn, I'll be the happiest person in my world of words.
                <br />
                <br />
                I started bearing my finance from when I was just only 15 by
                Tuitions and Part-time Jobs. Maybe I was the one who started
                Tuition at that age and obviously English version students
                without having an English version background. I become their
                favorite by quality time and a creative way to teach. Landed my
                first Full-time IT Job at the age of 17.
                <br />
                <br />I love to learn lots of things for my career and also for
                self-satisfaction. Coding became my way of refreshment and the
                debate as well but family is the greatest thing I ever loved
                which drives me for the next day and not to fade away.
              </p>
            </div>
          </Fade>

          {/* Education Section */}

          <Fade>
            <div className="container_b about_details">
              <h1>Education:</h1>
              <div className="container_a edi">
                <h2>Institutional Education:</h2>
                <div style={{ display: "grid" }} className="taw">
                  <Education data={eduData} />
                </div>
              </div>
              <div className="container_a edi a box_shadow">
                <h2 className="bb_dotted">Self-Learning:</h2>
                <p>
                  I learned most of my professional skills by self learning from
                  reputated institutions like <strong>CISCO</strong>,&nbsp;
                  <b>Linkedin Learning</b>,&nbsp;<strong>Google</strong>,&nbsp;
                  <strong>Coursera</strong>&nbsp;etc.
                </p>
              </div>
            </div>
          </Fade>

          {/* Volenteer experience */}

          <Fade>
            <div className="container_b about_details">
              <h1>Volunteer And Leadership Experience</h1>
              <div className="grid_parent small_box_parent">
                <div className="small_box big">
                  <h2 className="t_left">
                    Debater @ DCCDS
                    <address>
                      <span style={{ fontSize: "14px" }}>
                        Dhaka, Bangladesh | July(2019)-Now
                      </span>
                    </address>
                  </h2>
                  <p style={{ padding: "10px" }}>
                    ❏ Debating in Both Asian and British Parliamentary in Bangla
                    & English.
                    <br />
                    ❏ Helping junior debaters to grow and in their debate
                    careers.
                    <br />
                    <strong>
                      &nbsp;&nbsp;Awards
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;❏ Runners up (3rd GSSDC National
                      Debate Fest 2020)
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;❏ Champion ( Evaly-JUDO National
                      Debate Festival 2020)
                    </strong>
                    <br />
                  </p>
                </div>
                <div className="small_box big">
                  <h2 className="t_left">
                    Debater | Trainer | Organizing Secretary @ DAGMC
                    <address>
                      <span style={{ fontSize: "14px" }}>
                        Dhaka, Bangladesh | January(2017)- April(2019)
                      </span>
                    </address>
                  </h2>
                  <ol style={{ listStyleType: "none" }}>
                    <li>
                      ❏ Debated in Both Asian and British Parliamentary in
                      Bangla.
                    </li>
                    <li>
                      ❏ Helped junior debaters to grow and in their debate
                      careers.
                    </li>
                    <li>
                      ❏ Organized one Intra and one National Debate festival.
                      <ul
                        style={{
                          marginLeft: "30px",
                          fontStyle: "italic",
                          fontWeight: "600",
                        }}
                      >
                        <li>1st GMMSC National Podium 2019</li>
                        <li>1st GMMSC Podium-2018 (Intra)</li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </Fade>
        </CompAbout>
      </Fade>
    </Body>
  );
}

export default About;
