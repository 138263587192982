import styled from "styled-components";

export const CompProject = styled.main`
  margin-top: 86px;
  margin-bottom: 135px;
  background-color: #fff;
  @media (max-width: 480px) {
    margin-bottom: 150px;
  }
  .title {
    text-align: center;
    h1 {
      display: inline-block;
      margin: auto;
      text-align: center;
      width: 80vw;
      border-bottom: dotted 2px #dadfe9;
    }
  }
  .recentProject {
    display: grid;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  .project-page {
    max-width: 1200px;
    margin: auto;
    // border-left: solid 2px #dadfe9;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 2px;
      background-color: #dadfe9;
      top: 30px;
      left: 0;
      height: 97%;
    }
    h2 {
      // margin-top: 50px !important;
      padding-left: 42px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 40px;
        padding: 20px;
        top: 16px;
        left: 0 !important;
        border-top: solid 2px #dadfe9 !important;
        border-left: solid 2px #dadfe9 !important;
        border-radius: 15px 0 0 0 !important;
        color: #fff;
        @media (max-width: 768px) {
          top: 12px !important;
        }
      }
    }
    .project {
      display: grid;
      padding-left: 20px !important;
      @media (max-width: 768px) {
        padding: 10px 20px !important;
      }
    }
  }
`;
