import React from "react";

// animation importation
import Fade from "react-reveal/Fade";
import styled from "styled-components";

function ProjectBox(props) {
  const numbers = props.projectData;
  return (
    <>
      {React.Children.toArray(
        numbers.map((project) => (
          <Fade right>
            <Container>
              <a
                href={project.goTo}
                target="_blank"
                rel="noreferrer"
                className="project_proBox"
              >
                <img
                  className="overview__image"
                  src={project.image}
                  alt={`${project.name} Akhlak Hossain Jim Project`}
                />
                <h3>{project.name}</h3>
                <p style={{ overflow: "hidden" }}>
                  <strong>{project.name}</strong> {project.details}
                </p>
                <div className="backgrounded">
                  <img src={project.image} alt="" className="backgrounded" />
                </div>
              </a>
            </Container>
          </Fade>
        ))
      )}
    </>
  );
}

const Container = styled.div`
  max-width: 280px;
  min-width: 230px;
  height: 330px;
  border-radius: 16px;
  padding: 20px;
  margin: 10px auto !important;
  overflow: hidden;
  position: relative;
  // box-shadow: 0 0 10px #838181;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 300ms;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
  &:hover {
    box-shadow: 20px 20px 30px #bebebe, -20px -20px 30px #ffffff;
    transform: scale(1.05, 1.05);
  }
  &:focus {
    text-decoration: overline;
    transform: scale(1.05, 1.05);
  }
  a {
    &.project_proBox {
      color: #000 !important;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-items: space-between !important;
      h3 {
        border-bottom: 2px dotted #838181;
        margin: 6px 0;
        font-size: 16px !important;
        @media (min-width: 1400px) {
          font-size: 20px !important;
        }
      }
      img {
        &.overview__image {
          margin: -21px -21px 0;
          width: 121.5%;
          border: 1px solid #fff;
          border-radius: 5px !important;
        }
      }
      div {
        .backgrounded {
          position: absolute !important;
          left: -180px !important;
          bottom: -100px;
          object-fit: cover;
          object-position: center bottom;
          opacity: 0.2;
          z-index: -290;
          img {
            &.backgrounded {
              height: 100% !important;
              margin: 0 !important;
            }
          }
        }
      }
      p {
        font-size: 12px !important;
        height: 80px;
        margin: 2px;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 4px;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #0b5750;
          border-radius: 10px;
          padding: 5px;
          border: 3px solid #0b5750;
        }
        @media screen and (min-width: 1400px) {
          font-size: 16px;
        }
      }
    }
  }
`;

export default ProjectBox;
