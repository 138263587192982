// paths or professional certificates
import GoogleITSupportSpecial from "../media/Certificates/Google IT Support Professional Certificate.webp";
import linkedLearnPathBecWebDev from "../media/Certificates/Linkedin Learning Path Become a Software Developer.webp";
import linkedLearnPathNetpPrep from "../media/Certificates/Linkedin Learning Path Network+ prep.webp";

// web development
import linkedLearSuccessWebDev from "../media/Certificates/Linkedin Learning Succeeding In Web Development.webp";
import courseraULRespWebDev from "../media/Certificates/Coursera Responsive Website Basics University of London.webp";
import linkedLearHTMLEssenTrain from "../media/Certificates/Linkedin Learning HTML Essential Training.webp";
import linkedLearCSSEssenTrain from "../media/Certificates/Linkedin Learning CSS Essential Training.webp";
import linkedLearJSEssenTrain from "../media/Certificates/Linkedin Learning JavaScript Essential Training 2020.webp";
import linkedLearJSEssenTraini from "../media/Certificates/LinkedIn Learning JavaScript Essential Training 2021.webp";
import linkedLearLearnJava from "../media/Certificates/Linkedin Learning Java.webp";
import linkedLearLearnPython from "../media/Certificates/Linkedin Learning Python.webp";
import linkedLearLearnCSharp from "../media/Certificates/Linked Learning C sharp.webp";
import linkedLearProFounDatabase from "../media/Certificates/Linkedin Learning Programing Foundations_ DataBases.webp";
import linkedLearProFounSecurity from "../media/Certificates/Linkedin Learning Programing Foundation_ Web Security.webp";
import linkedLearProFounPytho from "../media/Certificates/LinkedIn-Learning Programming Foundation (python).webp";
import linkedLearProFounSQL from "../media/Certificates/Linkedin Learning SQL Programing.webp";
import pirpleFrontEnd from "../media/Certificates/Pirple-front-end-fundamentals.webp";
import bohubrihiWebDev from "../media/Certificates/Bohubrihi Web Development.webp";
import futurelearnCodeWeb from "../media/Certificates/Future Learn Learn to code for web.webp";
import futurelearnCodeWebpi from "../media/Certificates/Future Learn Learn to Code For web P2.webp";
import futurelearnUxdesign from "../media/Certificates/Future Learn UX.webp";
import futurelearnUxdesignpi from "../media/Certificates/Future Learn UX P2.webp";
import freeCodeCampRespWebdev from "../media/Certificates/freeCodeCamp Responsive Web Design.webp";
import soloLearnJquery from "../media/Certificates/SoloLearn-Jquery.webp";
import udemyWordpress from "../media/Certificates/Udemy-Make-website-on-Wordpress.webp";

// It Support
import ciscoNDGLinux from "../media/Certificates/CISCO NDG Linux.webp";
import linkedLearNetPPrepi from "../media/Certificates/Linkedin Learning Network+ prep 1 Understanding Networks.webp";
import linkedLearNetPPrepii from "../media/Certificates/Linkedin Learning Network+ prep 2 The Physical Network.webp";
import linkedLearNetPPrepiii from "../media/Certificates/LinkedIn Learning Network+ Prep 3 The World of TCP-IP.webp";
import linkedLearNetPPrepiv from "../media/Certificates/Linkedim Learning Network+ prep4 making TCP-IP Work.webp";
import linkedLearNetPPrepv from "../media/Certificates/LinkedIn Learning Network+ Prep 5 Securing TCP-IP.webp";
import linkedLearNetPPrepvi from "../media/Certificates/Linkedin Learning Network+ prep 6 Advance IP Networking.webp";
import linkedLearNetPPrepvii from "../media/Certificates/LinkedIn Learning Network+ Prep 7 Wireless, Virtual, Cloud, And Mobile Networking.webp";
import linkedLearNetPPrepviii from "../media/Certificates/LinkedIn Learning Network+ Prep 8 Building  a Real World Network.webp";
// import linkedLearNetPPrepix from "../media/Certificates/CompTIA Network+ (N10-007) Cert Prep: 9 Managing the Network.png";

// English
import ASUFoundationalPrinc from "../media/Certificates/ASU Foundational Principles.webp";
import ASUTheoriesofESL from "../media/Certificates/ASU Theories of Second Language Acquisition.png";
import OpenMOOCEngMedLitar from "../media/Certificates/Open-MOOC-English-for-Media-Literacy.webp";

// Personal Development
import googleDigiGarageDigiMarket from "../media/Certificates/Google Digital Garage Digital Marketting.webp";
import linkedLearResumeWrit from "../media/Certificates/Linkedin Learning Resume Writing.webp";
import NASBAResumeWrite from "../media/Certificates/National-Association-of-State-Boards-of-Accountancy-_NASBA_-Resume-Writing.webp";
import bylcxArtPubSpeaking from "../media/Certificates/BYLCx The Art of Public Speaking.webp";
import googleADSSendEmail from "../media/Certificates/Google Applied Digital Skills Send Email.webp";
import googleADSBusinessPlan from "../media/Certificates/Google Applied Digital Skill Write a business Plan.webp";
import udemyMessMarket from "../media/Certificates/Udemy-marketing-in-messenger.webp";
import googleDrawing from "../media/Certificates/Coursera Google Drawing.png";
import recoveringLayoff from "../media/Certificates/Recovering Layoff.png";
import feelingOverwhelmed from "../media/Certificates/Manage Feeling Overwhelem.png";

// CCA
import gssDCRunnersUp from "../media/Certificates/GSSDC Debate 2020 RunnersUp.webp";
import preWorld from "../media/Certificates/Pre-worlds-perticipation.webp";
import unicefWaterDay2021 from "../media/Certificates/unicef-worlds-water-day.webp";

const proCert = [
  {
    name: "Google IT Support Professional Certificate",
    imgSrc: `${GoogleITSupportSpecial}`,
  },
  {
    name: "Become a Software Developer",
    imgSrc: `${linkedLearnPathBecWebDev}`,
  },
  {
    name: "Prepare for the CompTIA Network+ (N10-007) Cetification",
    imgSrc: `${linkedLearnPathNetpPrep}`,
  },
];

const webReleted = [
  {
    name: "Succeeding In Web Development: Full Stack and Front-end",
    imgSrc: `${linkedLearSuccessWebDev}`,
  },
  {
    name: "Responsive Web Basics",
    imgSrc: `${courseraULRespWebDev}`,
  },
  {
    name: "HTML Essential Training",
    imgSrc: `${linkedLearHTMLEssenTrain}`,
  },
  {
    name: "CSS Essential Training",
    imgSrc: `${linkedLearCSSEssenTrain}`,
  },
  {
    name: "Javascript Essential Training 2020",
    imgSrc: `${linkedLearJSEssenTrain}`,
  },
  {
    name: "Javascript Essential Training 2021",
    imgSrc: `${linkedLearJSEssenTraini}`,
  },
  {
    name: "Learning Java",
    imgSrc: `${linkedLearLearnJava}`,
  },
  {
    name: "Learning Python",
    imgSrc: `${linkedLearLearnPython}`,
  },
  {
    name: "Learning C#",
    imgSrc: `${linkedLearLearnCSharp}`,
  },
  {
    name: "Programming Foundations: Database",
    imgSrc: `${linkedLearProFounDatabase}`,
  },
  {
    name: "Programming Foundations: Web Security",
    imgSrc: `${linkedLearProFounSecurity}`,
  },
  {
    name: "Programming Foundations: Fundamentals",
    imgSrc: `${linkedLearProFounPytho}`,
  },
  {
    name: "Programming Foundations: SQL",
    imgSrc: `${linkedLearProFounSQL}`,
  },
  {
    name: "Front-end Fundamentals",
    imgSrc: `${pirpleFrontEnd}`,
  },
  {
    name: "HTML5, CSS3 & Bootstrap 4 for Web Development",
    imgSrc: `${bohubrihiWebDev}`,
  },
  {
    name: "Learn to Code for Web",
    imgSrc: `${futurelearnCodeWeb}`,
    imgSrci: `${futurelearnCodeWebpi}`,
  },
  {
    name: "Creating Great User Experience",
    imgSrc: `${futurelearnUxdesign}`,
    imgSrci: `${futurelearnUxdesignpi}`,
  },
  {
    name: "Responsive Web Design",
    imgSrc: `${freeCodeCampRespWebdev}`,
  },
  {
    name: "JQuery",
    imgSrc: `${soloLearnJquery}`,
  },
  {
    name: "HTML5, CSS3 & Bootstrap 4 for Web Development Udemy",
    imgSrc: `${udemyWordpress}`,
  },
];

const iTSupport = [
  {
    name: "Cisco NDG Linux Terminal",
    imgSrc: `${ciscoNDGLinux}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 1 Understanding Networks",
    imgSrc: `${linkedLearNetPPrepi}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 2 The Physical Network",
    imgSrc: `${linkedLearNetPPrepii}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 3 The World of TCP/IP",
    imgSrc: `${linkedLearNetPPrepiii}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 4 Making TCP/IP Work",
    imgSrc: `${linkedLearNetPPrepiv}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 5 Securing TCP/IP",
    imgSrc: `${linkedLearNetPPrepv}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 6 Advanced IP Networking",
    imgSrc: `${linkedLearNetPPrepvi}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 7 Wireless, Virtual, Cloud, and Mobile Networking",
    imgSrc: `${linkedLearNetPPrepvii}`,
  },
  {
    name: "CompTIA Network+ (N10-007) Cert Prep: 8 Building a Real-World Network",
    imgSrc: `${linkedLearNetPPrepviii}`,
  },
  // {
  //   name: "CompTIA Network+ (N10-007) Cert Prep: 9 Managing the Network",
  //   imgSrc: `${linkedLearNetPPrepix}`,
  // },
];

const languLearn = [
  {
    name: "Teach English Now! Foundational Principles",
    imgSrc: `${ASUFoundationalPrinc}`,
  },
  {
    name: "Teach English Now! Theories of Second Language Acquisition",
    imgSrc: `${ASUTheoriesofESL}`,
  },
  {
    name: "English for Media Literacy",
    imgSrc: `${OpenMOOCEngMedLitar}`,
  },
];

const personalDev = [
  {
    name: "Fundamentals of Digital Marketing",
    imgSrc: `${googleDigiGarageDigiMarket}`,
  },
  {
    name: "Writing a Resume",
    imgSrc: `${linkedLearResumeWrit}`,
  },
  {
    name: "NASBA Resume Writing",
    imgSrc: `${NASBAResumeWrite}`,
  },
  {
    name: "How to Manage Feeling Overwhelmed",
    imgSrc: `${feelingOverwhelmed}`,
  },
  {
    name: "Recovering from a Layoff",
    imgSrc: `${recoveringLayoff}`,
  },
  {
    name: "Get Creative with Google Drawings for Education",
    imgSrc: `${googleDrawing}`,
  },
  {
    name: "The Art Of Public Speaking",
    imgSrc: `${bylcxArtPubSpeaking}`,
  },
  {
    name: "Send Professional Emails",
    imgSrc: `${googleADSSendEmail}`,
  },
  {
    name: "Write a Business Plan",
    imgSrc: `${googleADSBusinessPlan}`,
  },
  {
    name: "The Fundamentals of Digital Marketing(Messenger)",
    imgSrc: `${udemyMessMarket}`,
  },
];

const CCAcer = [
  {
    name: "3rd GSSDC National Debate Fest 2020 ( Runners Up) ",
    imgSrc: `${gssDCRunnersUp}`,
  },
  {
    name: "BDC Pre Worlds 2020",
    imgSrc: `${preWorld}`,
  },
  {
    name: "Unicef the World Water Day 2021",
    imgSrc: `${unicefWaterDay2021}`,
  },
];

export { proCert, webReleted, iTSupport, languLearn, personalDev, CCAcer };
