const eduData = [
  {
    title: "Become a Software Developer",
    institute: "From: LinkedIn Learning ",
    details: "Path (March-2021)",
  },
  {
    title: "Prepare for the CompTIA Network+ (N10-007) Certification",
    institute: "From: LinkedIn Learning ",
    details: "Path (March-2021)",
  },
  {
    title: "Google IT Support Professional Certificate",
    institute: "From: Google ",
    details: "Coursera (2021)",
  },
  {
    title: "HSC",
    institute: "From: Dhaka Commarce College ",
    details: "Dhaka, Bangladesh (Anticipated2021)",
  },
  {
    title: "SSC",
    institute: "From: Govt. Mohammadpur Model School and College ",
    details: "Dhaka, Bangladesh (2019)",
  },
];

export default eduData;
