import React from "react";
import { Link } from "react-router-dom";

// image importation
import jim from "../media/img/Akhlak Hossain Jim 480.webp";
import parthibAssociates from "../media/Icons/parthib associates.png";
import parthibBuilders from "../media/Icons/par-build.png";
import sj from "../media/Icons/sj.png";
import WO from "../media/Icons/wo-logo.png";
import AmTa from "../media/Icons/aamartaka-logo.png";
// import jimi from "../media/img/Akhlak Hossain Jim 960.jpg";
// import WOi from "../media/Icons/wo 480.jpg";
// import sji from "../media/Icons/sj 480.jpg";

// components importation
// import Header from "../Components/Header";
import Form from "../Components/Form";
import Appairel from "../Components/Appairel";
import MetaData from "../Components/MetaData";
import ProjectBox from "../Components/ProjectBox";

// data importation
import appairelData from "../data/appairelData";
import {
  learningPaths,
  totalNumOfTakenCourse,
  totalProjects,
} from "../data/summary";
import { projectDataWorks } from "../data/ProjectData";

// animation importation
import Fade from "react-reveal/Fade";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Body, SmallBoxParent } from "../style/components";
import { ContHome } from "../style/page/home";
import CalBox from "../Components/CalBox";

// import styled from "styled-components";

function Home() {
  let setting = {
    dots: true,
    // fade: true,
    infinity: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  return (
    <>
      <MetaData canon="http://ah-jim.web.app/" title="" />
      {/* <Header
        clsh="nav__items active"
        clsa="nav__items"
        clsp="nav__items"
        clsc="nav__items"
      /> */}

      <Fade>
        <Body className="Home">
          <ContHome>
            {/* title */}

            <Fade bottom>
              <div className="title">
                <h1>Akhlak Hossain Jim</h1>
                <h2
                  style={{
                    fontFamily: 'Shippori Mincho", serif',
                    fontWeight: "600",
                  }}
                >
                  Front-End Developer | IT Support Specialist | Traveler |
                  Curious Soul
                </h2>
                {/* <h2>
                Front-End Developer at{" "}
                <a
                  style={{ color: "#0b5750", textDecoration: "none" }}
                  href="https://aamartaka.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AamarTaka.com
                </a>
              </h2> */}
              </div>
            </Fade>

            {/* intro --image and description */}

            <div className="designation">
              <Fade>
                <img src={jim} alt="Avatar" />
              </Fade>
              <Fade>
                <p>
                  Hi, I'm a front-end developer and IT Support Specialist, from
                  Bangladesh. I create strenuous websites that are fast, easy to
                  use and build with best practices & utmost dedication. And
                  love to work with hardware and help people with their IT
                  devices.
                  <br />
                  I started creating and developing websites since I was 16. I
                  work to make websites more responsive, interactive, simple,
                  beautiful, and user-friendly, and easily readable for other
                  developers. And work with hardware in the background to ensure
                  everything is working properly.
                  <br />
                  I’m hungry to learn new things and from every single setback.
                  So I look forward to learning from all people and practice it
                  to better use.
                </p>
              </Fade>
            </div>

            <hr />

            {/* recent Projects */}

            <Fade>
              <h1
                style={{
                  textAlign: "center",
                  margin: "20px auto 0",
                  marginBottom: "30px",
                }}
              >
                Featured Project
              </h1>
              <SmallBoxParent
                className="featured-proj"
                style={{ padding: "0px", margin: "20px auto", gridGap: "20px" }}
              >
                <ProjectBox projectData={projectDataWorks} />
              </SmallBoxParent>
              <h2
                style={{
                  textAlign: "center",
                  margin: "0px auto 20px",
                }}
              >
                <Link
                  to="/project/"
                  style={{
                    color: "#0B5750",
                  }}
                >
                  See more...
                </Link>
              </h2>
            </Fade>

            <hr />

            {/* Companies I worked with */}

            <Fade>
              <div className="container_a companies">
                <div className="title">
                  <h1>Companies</h1>
                </div>
                <div className="bow">
                  <img
                    tabIndex="0"
                    src={AmTa}
                    alt=""
                    style={{ borderRadius: "0px" }}
                  />
                  <img tabIndex="0" src={WO} alt="" />

                  <img
                    tabIndex="0"
                    className="companies_logo"
                    src={parthibAssociates}
                    alt=""
                  />
                  <img tabIndex="0" src={parthibBuilders} alt="" />
                  <img tabIndex="0" src={sj} alt="" />
                </div>
              </div>
            </Fade>

            <hr />

            {/* summary Data */}

            <Fade>
              <div
                className="sumb"
                style={{ margin: "40px auto", padding: "20px 0" }}
              >
                <div className="title">
                  <h1>Summary</h1>
                </div>
                <section className="summary" style={{ marginTop: "10px" }}>
                  <CalBox name="Projects" num={totalProjects} />
                  <CalBox name="Learning Paths" num={learningPaths} />
                  <CalBox name="Courses I Took" num={totalNumOfTakenCourse} />
                </section>
              </div>
            </Fade>

            <hr />

            {/* Appairels */}

            <Fade>
              <div
                className="people say f_box_parent"
                style={{ padding: "20px 0" }}
              >
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <h1>Testimonials</h1>
                  <p>Check out other people's thoughts about me.</p>
                </div>
                <Slider {...setting}>
                  {React.Children.toArray(
                    appairelData.map((data) => (
                      <Appairel
                        avatar={data.avater}
                        link={data.link}
                        name={data.name}
                        desig={data.designation}
                        quote={data.quote}
                        place={data.place}
                      />
                    ))
                  )}
                </Slider>
              </div>
            </Fade>

            {/* form */}

            <Fade>
              <section
                id="frm"
                style={{ display: "grid", placeItems: "center" }}
              >
                <Form
                  title="Leave a Message"
                  subject="Genarel Message Home page"
                />
              </section>
            </Fade>
          </ContHome>
        </Body>
      </Fade>
    </>
  );
}

export default Home;
