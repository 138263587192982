import React, { useEffect, useState } from "react";

// icon importation
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import InstagramIcon from "@material-ui/icons/Instagram";
// import YouTubeIcon from "@material-ui/icons/YouTube";
import GitHubIcon from "@material-ui/icons/GitHub";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { ConFooter } from "../style/layout/footer";
import { useLocation } from "react-router-dom";

function Footer() {
  var date = new Date();
  var year = date.getFullYear();

  const [visiblity, setvisiblity] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setvisiblity(
        Math.round(
          (window.scrollY / (document.body.offsetHeight - window.innerHeight)) *
            100
        ) + "%"
      );
    });
  });

  let location = useLocation();

  return (
    <>
      {location.pathname === "/" ||
      location.pathname === "/project/" ||
      location.pathname === "/about/" ? (
        <ConFooter
          style={{
            opacity: `${
              visiblity === "96%"
                ? "1"
                : visiblity === "97%"
                ? "1"
                : visiblity === "98%"
                ? "1"
                : visiblity === "99%"
                ? "1"
                : visiblity === "100%"
                ? "1"
                : "0"
            }`,
            zIndex: `${visiblity === "100%" ? "10" : "-20"}`,
          }}
        >
          <div className="social_media_container">
            <a
              name="twitter Icon"
              href="http://twitter.com/ahossainjim/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon className="social__ico" />
            </a>
            <a
              name="Linkedin Icon"
              href="http://www.linkedin.com/in/akhlakhossainjim/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon className="social__ico" />
            </a>
            {/* <a
            name="Facebook Icon"
            href="http://www.facebook.com/akhlakhossainjim"
            target="_blank"
            rel="noopener noreferrer"
            >
            <FacebookIcon className="social__ico" />
            </a>
            <a
            name="instagram Icon"
            href="http://www.instagram.com/akhlak_hossain_jim/"
            target="_blank"
            rel="noopener noreferrer"
            >
            <InstagramIcon className="social__ico" />
            </a> 
            <a
            name="Youtube Icon"
            href="http://www.youtube.com/channel/UC-uGtNf3Nw8YLbqaqx8MAUA"
            target="_blank"
            rel="noopener noreferrer"
            >
            <YouTubeIcon className="social__ico" />
            </a>
          */}
            <a
              name="github Icon"
              href="http://github.com/Akhlak-Hossain-Jim"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon className="social__ico" />
            </a>
            <a name="email Icon" href="mailto:aklajim@yahoo.com">
              <MailOutlineIcon className="social__ico" />
            </a>
          </div>
          <p>
            &copy;{year} &nbsp;
            <a
              href="https://akhlak-hossain-jim.github.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Akhlak Hossain Jim
            </a>{" "}
            All Right Reserved.{" "}
            <a
              href="https://github.com/Akhlak-Hossain-Jim/ahjim/issues"
              target="_blank"
              rel="noopener noreferrer"
            >
              Report a problem
            </a>
          </p>
        </ConFooter>
      ) : null}
    </>
  );
}

export default Footer;
