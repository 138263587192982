const wrokHistoryData = [
  {
    title: "AamarTaka.com",
    type: "(Full-time)",
    role: "Front-end Developer",
    address: "Dhaka-",
    date: "[2021(June)-Present]",
    listcon: "Implement interactive and responsive web Front-end UI design",
    ili: "Developing web UI components and implementing with React.js",
    ilii: "Develop a flexible and well-structured front-end architecture, along with API",
    iliii: "Build reusable code and libraries for future use",
    iliv: "Ensure the technical feasibility of UI/UX design",
  },
  {
    title: "Woman Opportunities",
    type: "(Contract)",
    role: "Front-end Developer",
    address: "Remote",
    listcon:
      "Modern prototype designing for both user-friendly and easy to use",
    ili: "Writing cleanly reusable with modern javascript library React.js",
    ilii: "Using modern CSS precompiler (S)CSS",
  },
  {
    title: "Bangladesh Associate of IT Solution",
    type: "(Full-time)",
    role: "Front-end Developer",
    address: "Dhaka-",
    date: "[January-2021]",
    listcon:
      "Writing cleanly reusable Front-end code with HTML, (S)CSS, JavaScript",
    ili: "Collaborating with other team meats and with the UI team.",
  },
  {
    title: "Parthib Associates",
    type: "(Contract)",
    role: "Front-end Developer",
    address: "Remote",
    listcon:
      "Modern prototype designing for both user-friendly and easy to use",
    ili: "Writing cleanly reusable with Html, CSS, Bootstrap, and Javascript",
    ilii: "Using modern CSS precompiler (S)CSS",
  },
  {
    title: "Shuvro Jaya",
    type: "(Part-time)",
    role: "Tech Lead",
    address: "Dhaka-",
    date: "[2019-2020(Octaber)]",
    listcon: "Online presence management",
    ili: "Public Relations through Facebook Messenger",
    ilii: "Customer Support",
    iliii: "Marketing strategist",
  },
];

export default wrokHistoryData;
