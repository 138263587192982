const appairelData = [
  {
    name: "Nazmul Haque",
    avater: "/img/test/parthib.webp",
    link: "https://www.linkedin.com/in/nhparthib45/",
    designation: "Parthib Associates",
    quote:
      "Akhlak Hossain Jim is a talented and highly hard working individual whose work reflects his work ethic in the best possible way. His works were highly agile and modern and his websites are top quality. His commitment to his work and to his clients are really exceptional and his punctuality is ardent. Overall his professionalism and commitment makes him one of the best developers there is, and I wish him all the very best luck for the future. ",
    place: "12 May 2021, Bangladesh",
  },
  {
    name: "Sanjida Ohi",
    avater: "https://ohistic.web.app/avaters/avater1.webp",
    link: "https://ohistic.web.app/about/",
    designation: "Creative Artist",
    quote: `So as you guys know I have a lil page for my art stuffs. It's growing day by day. I wanted to have an website for my page,but meh as I am not tech bug and don't have any knowledge about these, my desire was only just a dream.😐But hold on there, my dream turned out to be true because of one tech mate. Let me present my front-end developer and IT Support specialist buddy Akhlak Hossain Jim. His works are filled with dedication and skills. I loved the website he made for me and admire his skills. I won't make this too long so I thank him wholeheartedly for making such simply gorgeous website for me.❣️
      If you want any further information you can talk with him.
      Cheerio.`,
    place: "07 August 2021, Bangladesh",
  },
  {
    name: "Anik",
    avater: "/img/test/anik.webp",
    link: "https://github.com/anik4997",
    designation: "Learner",
    quote:
      "Akhlak is my mentor and he is a very good developer whenever I have faced a problem he would solve them. Akhlak and I was working together in different project he is very skilled and hard working.",
    place: "04 May 2021, Bangladesh",
  },
  {
    name: "Sazzad Hossain Shuvo",
    avater: "/img/test/shuvo.webp",
    link: "https://github.com/sazzad1522004",
    designation: "Learner",
    quote:
      "This guy is really awesome. I was stuck with my code as a new developer but he fixed it within an hour. Thank you so much. Highly recommended.",
    place: "22 April 2021, Bangladesh",
  },
];

export default appairelData;
