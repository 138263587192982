import React, { useState } from "react";

function CertificateBox({ name, imgSrc, imgSrci }) {
  let desText = "Akhlak Hossain Jim Certification " + name;
  const [imgsit, setImgsit] = useState(
    "certificate_image full_scren_container grid_parent drown"
  );
  return (
    <>
      {/* <Fade right> */}
      <div
        className="cerName_cer"
        tabIndex="0"
        onClick={() => {
          imgsit === "certificate_image full_scren_container grid_parent drown"
            ? setImgsit("certificate_image full_scren_container grid_parent")
            : setImgsit(
                "certificate_image full_scren_container grid_parent drown"
              );
        }}
      >
        <h3>{name}</h3>
        <div className={imgsit}>
          <img src={imgSrc} alt={desText} />
          {imgSrci && <img src={imgSrci} alt={desText} />}
        </div>
      </div>
      {/* </Fade> */}
    </>
  );
}

export default CertificateBox;
