import React from "react";
import styled from "styled-components";

function SmallBox({
  title,
  type,
  date,
  role,
  address,
  listcon,
  ili,
  ilii,
  iliii,
  iliv,
}) {
  return (
    <ConSmallBx>
      <h2 className="t_left">
        {title}&nbsp;
        <wbr />
        <span className="type">{type}</span>
        <address>
          {address}
          <span>{date}</span>
        </address>
      </h2>
      <ul>
        {role && <h3 style={{ fontSize: "14px" }}>{role}</h3>}
        {listcon && <li>{listcon}</li>}
        {ili && <li>{ili}</li>}
        {ilii && <li>{ilii}</li>}
        {iliii && <li>{iliii}</li>}
        {iliv && <li>{iliv}</li>}
      </ul>
    </ConSmallBx>
  );
}

const ConSmallBx = styled.div`
  font-family: "Times New Roman", Times, serif;
  -webkit-align-self: center !important;
  align-self: center !important;
  justify-self: center !important;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #fff;
  max-width: 300px;
  min-width: 220px;
  border-radius: 16px;
  padding: 20px;
  margin: auto;
  max-height: 100%;
  h2 {
    font-family: "Times New Roman", Times, serif;
    text-align: left;
    font-style: italic;
    font-size: 16px !important;
    font-weight: 700;
    border-bottom: dotted #d4d4d2;
    line-height: 25px;
    span {
      color: #80807d;
      font-size: 16px;
      line-height: 1rem;
    }
    address {
      font-size: 14px;
      span {
        font-size: 14px;
      }
    }
  }
  ul {
    font-size: 16px !important;
    padding: 10px;
    @media (min-width: 769px) {
      font-size: 13px !important;
    }
    h3 {
      font-size: 14px;
    }
  }
`;

function SB({ title, institute, details }) {
  return (
    <ContSB className="small_box">
      <h2>{title}</h2>
      <p style={{ padding: "10px 0" }}>
        <strong>{institute}</strong>
        {details}
      </p>
    </ContSB>
  );
}

const ContSB = styled.div`
  height: 100%;
  font-family: "Times New Roman", Times, serif;
  -webkit-align-self: center !important;
  align-self: center !important;
  justify-self: center !important;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #fff;
  max-width: 300px;
  min-width: 220px;
  border-radius: 16px;
  padding: 20px;
  margin: auto;
  h2 {
    font-size: 16px !important;
    text-align: center;
    border-bottom: dotted #d4d4d2;
    font-weight: 900 !important;
  }
  p {
    padding: 10px 0px;
    @media (min-width: 769px) {
      font-size: 13px !important;
    }
  }
`;
export { SmallBox, SB };
