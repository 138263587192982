import styled from "styled-components";

export const ContHome = styled.div`
  .title {
    border-bottom: dotted #d4d4d2;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
    font-family: "Shippori Mincho", serif;
    h1 {
      text-align: center !important;
      font-weight: 800;
    }
    h2 {
      padding-bottom: 10px;
      color: #141412ce;
      font-style: oblique !important;
      text-align: center !important;
    }
  }
  .designation {
    margin: auto;
    display: grid;
    max-width: 1266px;
    padding: 100px 0;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: center;
    justify-content: center !important;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 40px;
    }
    @media (hover: none) and (pointer: coarse) {
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 40px;
    }
    img {
      justify-self: center;
      height: 350px !important;
      width: 350px !important;
      border-radius: 50%;
      // box-shadow: 0 0 10px 2px #838181;
      box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
      @media (max-width: 768px) {
        max-width: 260px !important;
        max-height: 260px !important;
      }
      @media (hover: none) and (pointer: coarse) {
        max-width: 100vw !important;
        max-height: 100vw !important;
      }
      @media (hover: none) and (pointer: coarse) {
        max-width: 80vw !important;
        max-height: 80vw !important;
      }
    }
    p {
      // box-shadow: 0 0 10px 2px #838181;
      box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
      border-radius: 16px;
      line-height: 30px;
      font-size: 16px;
      padding: 30px;
      @media (max-width: 768px) {
        padding: 20px;
      }
    }
  }
  .featured-proj {
    display: grid;
    max-width: 1200px;
    margin: auto;
  }
  .working {
    margin: 50px auto;
    // box-shadow: 0 0 10px 5px #838181;
    box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
    border-radius: 16px;
    padding: 30px;
    @media (max-width: 768px) {
      padding: 20px;
    }
    h1 {
      margin: auto;
      text-align: center;
      border-bottom: dotted #d4d4d2;
    }
  }
  .companies {
    margin: 50px auto;
    max-width: 1200px;
    // box-shadow: 0 0 10px 2px #838181;
    box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
    border-radius: 16px;
    padding: 30px;
    @media (max-width: 768px) {
      padding: 20px;
    }
    @media (max-width: 768px) {
      margin: 20px;
    }
    h1 {
      margin: auto;
      text-align: center;
      /* border-bottom: 1px dotted #d4d4d2; */
    }
    .bow {
      margin: auto;
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      align-items: center;
      padding: 50px 0;
      justify-content: center !important;
      @media (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
        padding: 40px 0;
      }
      @media (max-width: 594px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 448px) {
        grid-template-columns: repeat(2, 1fr);
      }
      img {
        width: 130px;
        border-radius: 30%;
        background-color: transparent;
        transition: all 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        // box-shadow: 0 0 10px #838181;
        filter: sepia(100%) brightness(80%) hue-rotate(360deg) saturate(0%)
          opacity(100%) contrast(90%) invert(17%);
        @media (hover: none) and (pointer: coarse) {
          filter: none;
        }
        &:hover {
          // transform: scale(1.05, 1.05);
          filter: none;
          // box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
        }
        &:focus {
          // transform: scale(1.05, 1.05);
          filter: none;
          // box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
        }
      }
    }
  }
  .sumb {
    max-width: 1000px;
    margin: auto;
    .summary {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
`;
