import {
  projectDataWorks,
  projectDataDemos,
  projectDataPortfolios,
  projectDataProjects,
  projectDataFuns,
  frontendioProj,
} from "./ProjectData";

const totalProjects =
  projectDataWorks.length +
  projectDataDemos.length +
  projectDataPortfolios.length +
  projectDataProjects.length +
  projectDataFuns.length +
  frontendioProj.length -
  1;

var certifiedCourses = 49;
var totalNumOfTakenCourse = 110;
var auditCourses = totalNumOfTakenCourse - certifiedCourses;
var learningPaths = 3;

export {
  certifiedCourses,
  totalNumOfTakenCourse,
  auditCourses,
  totalProjects,
  learningPaths,
};
