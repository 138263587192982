import parthib_associates from "../media/Project/Akhlak Hossain Jim Projects Parthib Associates 960.webp";
import ohistic from "../media/Project/ohi-pc-view.webp";
import theWavesVir from "../media/Project/the-waves-book-s.webp";
import shuvro_jaya from "../media/Project/Akhlak-Hossain-Jim-Project-Invoice-Shuvro-Jaya-960.webp";

import Bloodbank_demo from "../media/Project/Blood donation project.webp";
import amajone_clone from "../media/Project/Akhlak Hossain Jim Projects Amazon clone 960.webp";
import ztnaxLTD from "../media/Project/zynaxLTD demo project.webp";
import DisneyPlusClone from "../media/Project/Disney plus clone.webp";
import TeslaClone from "../media/Project/teslaclone.webp";
import xpeedstudiojsdemo from "../media/Project/xpeedstudio-js-demo.webp";

import Analog_clock from "../media/Project/AKhlak Hossain Jim Projects Analog Clock 960.webp";
import Tiping_speed from "../media/Project/Akhlak-Hossain-Jim-Projects-Typing-speed-testing-960.webp";
import Digital_clock from "../media/Project/Akhlak-Hossasin-Jim-Projects-Digital-Clock-480.webp";
import interest_calcu from "../media/Project/interest calculator .webp";
import scrolltracingbar from "../media/Project/scroll tracing bar.webp";

import sunnyAgency from "../media/Project/sunny-agency.webp";
import frntmentor3grid from "../media/Project/3-grid.webp";
import roomlanding from "../media/Project/room-landing-web.webp";

import Portfolio_ahj from "../media/Project/Akhlak-Hossain-Jim-Project-Portfolio-960.webp";
import Portfolio_ahj2 from "../media/Project/Akhlak-Hossain-Jim-Project-Portfolio-2-960.webp";
import Portfolio_ahj3 from "../media/Project/Akhlak-Hossain-Jim-Project-Portfolio-3-960.webp";
import Portfolio_ahj4 from "../media/Project/Akhlak-Hossain-Jim-Project-Portfolio-4-480.webp";

import Mouse_tracer from "../media/Project/Akhlak-Hossain-Jim-Projects-Mouse-tracing-960.webp";

const projectDataWorks = [
  {
    goTo: "https://book-the-waves-virginia-woolf.web.app/",
    name: "The Waves(Book)",
    image: `${theWavesVir}`,
    details:
      "a digitalized public domain (where copyright is Life+70.) , Created using React.js, Styled-Component, and Firebase.",
  },
  {
    goTo: "https://ohistic.web.app/",
    name: "Ohistic",
    image: `${ohistic}`,
    details:
      "a creative artist portfolio, Created using React.js, (S)CSS, and Firebase.",
  },
  {
    goTo: "https://parthibassociates.web.app/",
    name: "Parthib Associates",
    image: `${parthib_associates}`,
    details:
      "is an engineering company's website Created with HTML, (s)CSS, Javascript, and Bootstrap",
  },
  {
    goTo: "https://invoice-jim.netlify.app/",
    name: "Invoicing App",
    image: `${shuvro_jaya}`,
    details: "Created for Shuvro Jaya with Reactjs, (S)CSS and Netlify.",
  },
];

const projectDataDemos = [
  {
    key: 206,
    goTo: "https://xpeedstudio-js-dev-test.web.app/",
    name: "XpeedStudio Job test",
    image: `${xpeedstudiojsdemo}`,
    details: "Created using React, Styled-component, (S)CSS and firebase.",
  },
  {
    key: 205,
    goTo: "https://ahj-clone-tesla.web.app/",
    name: "Tesla Landing Page",
    image: `${TeslaClone}`,
    details:
      "Created with React, Redux, Styled-component, React Helmet and firebase",
  },
  {
    key: 204,
    goTo: "https://ahj-disney-clone.web.app/",
    name: "Disney+ Clone app",
    image: `${DisneyPlusClone}`,
    details:
      "Created with React, Redux, Styled-component, React Helmet and firebase",
  },
  {
    key: 203,
    goTo: "https://zaynax-ltd-demo.web.app/",
    name: " E-commerce Product Page Demo",
    image: `${ztnaxLTD}`,
    details:
      "Created with Reactjs, MaterailUI, React Helmet, (S)CSS and firebase for Zynax LTD",
  },
  {
    key: 202,
    goTo: "https://bloodbank.demos.ahjim.com/",
    name: "Blood Donation Website Demo",
    image: `${Bloodbank_demo}`,
    details: "Created with Reactjs, (S)CSS and firebase",
  },
  {
    key: 201,
    goTo: "https://clone-ahj.web.app",
    name: "Amazone Clone",
    image: `${amajone_clone}`,
    details: "Created with Reactjs and firebase",
  },
];

const projectDataPortfolios = [
  {
    key: 304,
    goTo: "https://beta-ahj.web.app/",
    name: "Portfolio(Own)",
    image: `${Portfolio_ahj}`,
    details: "{Old}Created with Reactjs, (S)CSS and firebase",
  },
  {
    key: 303,
    goTo: "https://akhlak-hossain-jim.github.io/old/",
    name: "Portfolio(Own)",
    image: `${Portfolio_ahj2}`,
    details: "{Old}Created with HTML, (S)CSS and JS",
  },
  {
    key: 302,
    goTo: "https://ahj-portfolio-cpc-5drc.netlify.app/",
    name: "Portfolio(Own)",
    image: `${Portfolio_ahj3}`,
    details: "{Old}Created Clever Programmer",
  },
  {
    key: 301,
    goTo: "https://ahj-ii.web.app/",
    name: "Portfolio(Own)",
    image: `${Portfolio_ahj4}`,
    details: "{Old}Created with Reactjs, (S)CSS and firebase",
  },
];

const projectDataProjects = [
  {
    key: 405,
    goTo: "https://scroll-tracing-bar.web.app/",
    name: "Scroll tracing bar",
    image: `${scrolltracingbar}`,
    details:
      "was bootstrapped with Create React App, Styled-Component, and Firebase.",
  },
  {
    key: 404,
    goTo: "https://akhlak-hossain-jim.github.io/vftvk-Simple-Interest-Calculator/",
    name: "Simple Interest Calculator",
    image: `${interest_calcu}`,
    details: "Created with HTML, CSS and Javascript.",
  },
  {
    key: 403,
    goTo: "https://clock-ahj.netlify.app/",
    name: "Digital Clock",
    image: `${Digital_clock}`,
    details: "Created with React.js, (S)CSS and Netlify.",
  },
  {
    key: 402,
    goTo: "https://akhlak-hossain-jim.github.io/old/Projects/typeing_speed_testing/",
    name: "Typing Speed Testing",
    image: `${Tiping_speed}`,
    details: "Created with HTML, (S)CSS, JS.",
  },
  {
    key: 401,
    goTo: "https://akhlak-hossain-jim.github.io/old/Projects/Clock/",
    name: "Analog Clock",
    image: `${Analog_clock}`,
    details: "Created with HTML, (S)CSS, JS.",
  },
];

const projectDataFuns = [
  {
    key: 501,
    goTo: "https://akhlak-hossain-jim.github.io/old/Projects/Mouse%20tracer/",
    name: "Digital Clock",
    image: `${Mouse_tracer}`,
    details: "Created with HTML, (S)CSS, JS.",
  },
];

const projectDataRecent = [
  {
    goTo: "https://book-the-waves-virginia-woolf.web.app/",
    name: "The Waves(Book)",
    image: `${theWavesVir}`,
    details:
      "a digitalized public domain (where copyright is Life+70.) , Created using React.js, Styled-Component, and Firebase.",
  },
  {
    goTo: "https://ohistic.web.app/",
    name: "Ohistic",
    image: `${ohistic}`,
    details:
      "a creative artist portfolio, Created using React.js, (S)CSS, and Firebase.",
  },
  {
    goTo: "https://xpeedstudio-js-dev-test.web.app/",
    name: "XpeedStudio Job test",
    image: `${xpeedstudiojsdemo}`,
    details: "Created using React, Styled-component, (S)CSS and firebase.",
  },
];

export const frontendioProj = [
  {
    goTo: "https://ahj-frontendmentorio-iii.netlify.app/",
    name: "Room Landing Page",
    image: `${roomlanding}`,
    details:
      "a frontendmentor challenge, Created using React.js, Styled-Component, and Firebase.",
  },
  {
    goTo: "https://ahj-frontendmentorio-ii.netlify.app/",
    name: "Sunny-agency landing page",
    image: `${sunnyAgency}`,
    details:
      "a frontendmentor challenge, Created using React.js, Styled-Component, react-icons, and Firebase.",
  },
  {
    goTo: "https://akhlak-hossain-jim.github.io/3-column-preview-card-component-main/",
    name: "3-column-preview-card-component",
    image: `${frntmentor3grid}`,
    details:
      "a frontendmentor challenge, Created using HTML, CSS, and github page.",
  },
];

export {
  projectDataWorks,
  projectDataDemos,
  projectDataPortfolios,
  projectDataProjects,
  projectDataFuns,
  projectDataRecent,
};
