import styled from "styled-components";

export const CompApp = styled.div`
max-width:1440px;
margin:0 auto;
  div {
    &.people {
      &.say {
        &.f_box_parent {
          ul li {
            background-color: #bebebe;
            height: 5px;
            width: 30px;
            border-radius: 5px;
            button {
              &:before {
                font-size: 0px;
                color: transparent;
              }
            }
          }
          li.slick-active {
            width: 60px !important;
            background-color: #0b5750;
          }
        }
      }
    }
  }
`;
