import React from "react";
import { SB } from "./SmallBox";

function Education(props) {
  const data = props.data;
  const allWorks = data.map((e) => (
    <SB
      title={e.title}
      institute={e.institute}
      details={e.details}
      key={e.title}
    />
  ));
  return <>{allWorks}</>;
}

export default Education;
