import React from "react";
import emailjs from "emailjs-com";
import styled from "styled-components";
import { Button } from "../style/components";

function Form({ title, subject }) {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_vlqgvc7",
        e.target,
        "user_QCp5pLhEifTk4hSflAOkZ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    alert("Your message sent successfully");
    e.target.reset();
  }

  return (
    <Container
      className="contact__form"
      onSubmit={sendEmail}
      style={{ maxWidth: "360px" }}
    >
      <h2>{title}</h2>
      <input type="hidden" required name="subject" value={subject} />
      <label>
        Name <span>*</span> <br />
        <input type="text" required name="name" placeholder="Jane Doe" />
      </label>
      <label>
        Email <span>*</span>
        <br />
        <input
          type="email"
          required
          name="email"
          placeholder="jane.doe@gmail.com"
        />
      </label>
      <label>
        Message <span>*</span>
        <br />
        <textarea
          name="message"
          placeholder="Hey Jim, How are you doing lately?"
        />
      </label>
      <Button>Send</Button>
    </Container>
  );
}

const Container = styled.form`
  margin: 40px auto;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
  padding: 30px;
  border-radius: 16px;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu", sans-serif;
  h2 {
    font-family: "Ubuntu", sans-serif;
    border-bottom: dotted #d4d4d2;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    margin: 5px 0;

    span {
      color: #0b5750 !important;
      font-weight: 900;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1400px) {
      font-size: 16px !important;
    }
  }
  input {
    height: 30px;
    width: 200px;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
    outline: none;
    border: none;
    padding: 5px 10px;
    &:focus {
      box-shadow: 0 0 5px rgb(78, 207, 175);
    }
  }
  textarea {
    height: 150px !important;
    width: 300px !important;
    box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
    outline: none;
    border: none;
    font-size: 15px;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    border-radius: 5px;
    padding: 5px 10px;
    &:focus {
      outline: none;
      box-shadow: 0 0 7px rgb(78, 207, 175);
    }
    @media (max-width: 448px) {
      width: 78vw !important;
    }
  }
  button {
    margin: 15px 0 0 0;
    width: 90px;
    border: none;
    font-weight: 900;
    font-size: 16px;
    @media (min-width: 1400px) {
      font-size: 18px !important;
    }
  }
`;

export default Form;
