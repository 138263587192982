import styled from "styled-components";

export const SmallBoxParent = styled.div`
  padding: 30px 0 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-items: center;
  justify-content: center !important;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 0;
  }
  @media (max-width: 548px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 0;
  }
`;

export const SmallBo = styled.div`
  font-family: "Times New Roman", Times, serif;
  align-self: center !important;
  justify-self: center !important;
  // box-shadow: 0 0 10px #838181;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
  max-width: 300px;
  min-width: 220px;
  // height: 100%;
  border-radius: 16px;
  padding: 20px;
  margin: auto;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (min-width: 449px) {
    height: 100%;
  }
  h2 {
    font-size: 16px !important;
    text-align: center;
    border-bottom: dotted #d4d4d2;
    &.t_left {
      text-align: left !important;
      font-style: italic;
      .type {
        color: #80807d;
        font-size: 16px;
        line-height: 1rem;
      }
    }
    address {
      font-size: 14px;
      span {
        color: #80807d;
        font-size: 12px;
      }
    }
  }

  p,
  ul {
    font-size: 16px !important;
    padding: 10px;
    @media (min-width: 769px) {
      font-size: 13px !important;
    }
  }
  li {
    font-size: 14.5px;
  }
  ol {
    font-size: 16px !important;
    padding: 10px;
    @media (min-width: 769px) {
      font-size: 13px !important;
    }
  }
`;

export const XsBox = styled.div`
  align-self: center !important;
  justify-self: center !important;
  // box-shadow: 0 0 10px #838181;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
  max-width: 300px;
  min-width: 220px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
  padding: 30px;
  margin: auto;
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (min-width: 449px) {
    height: 100%;
  }
`;

export const Button = styled.button`
  background-image: linear-gradient(
    to right,
    #c2bcb7 0%,
    #e6e0da 51%,
    #ffffff 100%
  );
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-decoration: none;
  border-radius: 18px;
  padding: 15px;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 30px #ffffff;
  font-size: 20px;
  font-weight: 300;
  transition: 0.7s;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px !important;
  }
  &:hover {
    background-image: linear-gradient(
      to right,
      #000000 0%,
      #464646 51%,
      #8f8f8f 100%
    );
    color: #ffffff;
  }
  &:focus {
    outline: none;
    background-image: linear-gradient(
      to right,
      #000000 0%,
      #464646 51%,
      #8f8f8f 100%
    );
    color: #ffffff;
  }
`;

export const Body = styled.main`
  margin-top: 140px;
  margin-bottom: 135px;
  background-color: #fff;
  @media (max-width: 480px) {
    margin-bottom: 150px;
  }
`;
