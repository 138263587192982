import React from "react";
import { Helmet } from "react-helmet";

function MetaData({ canon, title }) {
  return (
    <Helmet>
      <meta
        name="description"
        content="About | Akhlak Hossain Jim | Front-end Web Developer, Loves Travelling, passionate to learn things, spacially in IT field"
      />
      <link rel="canonical" href={window.location} />

      <meta
        property="og:title"
        content="About | Akhlak Hossain Jim :: Front-end+Travelling+Curious Soul"
      />
      <meta
        property="og:description"
        content="About | Akhlak Hossain Jim | Front-end Web Developer, Loves Travelling, passionate to learn things, spacially in IT field"
      />
      <meta
        property="og:image"
        content="https://ah-jim.web.app/static/media/Akhlak%20Hossain%20Jim%20480.5431faf5.webp"
      />
      <meta property="og:url" content="https://ah-jim.web.app/about/" />
      <meta property="og:type" content="website" />

      <meta
        name="twitter:title"
        content="About | Akhlak Hossain Jim :: Front-end+Travelling+Curious Soul"
      />
      <meta
        name="twitter:description"
        content="About | Akhlak Hossain Jim | Front-end Web Developer, Loves Travelling, passionate to learn things, spacially in IT field"
      />
      <meta
        name="twitter:image"
        content="https://ah-jim.web.app/static/media/Akhlak%20Hossain%20Jim%20480.5431faf5.webp"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@ahossainjim" />
      <meta name="twitter:site" content="@ahossainjim" />
      <title>
        {title} Akhlak Hossain Jim :: Front-end+Travelling+Curious Soul
      </title>
    </Helmet>
  );
}

export default MetaData;
