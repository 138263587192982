import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages

import Home from "./Pages/Home";
import About from "./Pages/About";
// import Contact from "./Pages/Contact";
import Project from "./Pages/Project";
import NoMatch from "./Pages/NoMatch";
import Certificates from "./Pages/Certificates";

// layout

import Footer from "./Components/Footer";
import Header from "./Components/Header";

// style
import { CompApp } from "./style/base";

function App() {
  return (
    <CompApp>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about/">
            <About />
          </Route>
          <Route exact path="/about/certificates/">
            <Certificates />
          </Route>
          {/* <Route exact path="/about/resume/">
          <Resume />
        </Route> */}
          <Route exact path="/project/">
            <Project />
          </Route>
          {/* <Route exact path="/contact/">
          <Contact />
        </Route> */}
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </CompApp>
  );
}

export default App;
