import React, { useState, useEffect } from "react";
import logo from "../media/Icons/AkhlakhossainJim_logo.png";
import { Link, useLocation } from "react-router-dom";
import { ConHeader, ConHeaderOldMsg } from "../style/layout/header";

function Header() {
  const [toggler, setToggler] = useState(false);

  const [activeNav, setactiveNav] = useState();

  const [mOver, setMOver] = useState(0);

  let location = useLocation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  useEffect(() => {
    setToggler(false);
  }, [location.pathname]);

  useEffect(() => {
    toggler
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [toggler]);

  useEffect(() => {
    location.pathname === "/"
      ? setactiveNav(1)
      : location.pathname === "/project/"
      ? setactiveNav(2)
      : location.pathname === "/about/"
      ? setactiveNav(3)
      : location.pathname === "/about/certificates"
      ? setactiveNav(3)
      : setactiveNav();
  }, [location.pathname]);

  return (
    <>
      {location.pathname === "/" ||
      location.pathname === "/project/" ||
      location.pathname === "/about/" ? (
        <>
          <ConHeader>
            <ConHeaderOldMsg className="header_message_old">
              You are viewing the older version.
              <wbr /> Last update <strong>June, 2021</strong>. <wbr />
              <strong>
                View the new one{" "}
                <a
                  href={
                    window.location.origin === "https://ah-jim.web.app"
                      ? "https://ah-jim-seed.web.app"
                      : "https://ah-jim.web.app"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Here
                </a>
                .
              </strong>
            </ConHeaderOldMsg>
            <div className="header_content_Container">
              <div className="header__box">
                <div className="header__logo">
                  <Link name="logo" to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>

                <div
                  className={`phone__nav__icon `}
                  onClick={() => setToggler(!toggler)}
                  tabIndex="0"
                >
                  <div
                    className={toggler ? "hamInner active" : "hamInner"}
                  ></div>
                </div>
              </div>

              <nav
                className={`big__nav ${toggler ? "phone__nav" : ""}`}
                id="nav"
              >
                <Link
                  className={`nav__items ${activeNav === 1 ? "active" : ""}  ${
                    mOver === 0 ? "" : mOver === 1 ? "hover" : "nover"
                  }`}
                  onBlur={() => setMOver(0)}
                  onFocus={() => setMOver(1)}
                  onMouseOver={() => setMOver(1)}
                  onMouseOut={() => setMOver(0)}
                  to="/"
                >
                  Home
                </Link>

                <Link
                  className={`nav__items ${activeNav === 2 ? "active" : ""}  ${
                    mOver === 0 ? "" : mOver === 2 ? "hover" : "nover"
                  }`}
                  onBlur={() => setMOver(0)}
                  onFocus={() => setMOver(2)}
                  onMouseOver={() => setMOver(2)}
                  onMouseOut={() => setMOver(0)}
                  to="/project/"
                >
                  Project
                </Link>

                <Link
                  className={`nav__items ${activeNav === 3 ? "active" : ""}  ${
                    mOver === 0 ? "" : mOver === 3 ? "hover" : "nover"
                  }`}
                  onBlur={() => setMOver(0)}
                  onFocus={() => setMOver(3)}
                  onMouseOver={() => setMOver(3)}
                  onMouseOut={() => setMOver(0)}
                  to="/about/"
                >
                  About
                </Link>

                {/* <Link
            className={clsc}
            id="a_contact"
            onBlur={mUContact}
            onFocus={mOContact}
            onMouseOver={mOContact}
            onMouseOut={mUContact}
            to="/contact/"
          >
            Contact
          </Link> */}
              </nav>
            </div>
          </ConHeader>
        </>
      ) : null}
    </>
  );
}

export default Header;
