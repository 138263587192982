import styled from "styled-components";

export const ConFooter = styled.footer`
  background-color: rgba(248, 248, 248, 0.967);
  // background-color: #000;
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  position: fixed;
  bottom: 0;
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;
  z-index: -20;
  padding: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 20px 20px;
  }
  .social_media_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    a {
      padding: 7px;
      color: #000;
      transition: 0.3s;
      cursor: pointer;
      .social__ico {
        font-size: 30px;
      }
      &:hover {
        color: #0b5750;
        transform: scale(1.1, 1.1);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      }
      &:focus {
        outline: dashed 2px #0b5750;
        color: #0b5750;
        transform: scale(1.1, 1.1);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      }
    }
  }
  p {
    font-family: "Akaya Telivigala", cursive;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    a {
      font-family: "Caveat", cursive !important;
      font-size: 18px !important;
      font-weight: 900 !important;
      color: #0b5750;
      &:hover {
        color: #0b5750;
        transform: perspective(1000px) translateZ(100px) scale(1.3, 1.3);
      }
      &:focus {
        outline: dashed 2px #0b5750;
        color: #0b5750;
        transform: perspective(1000px) translateZ(100px) scale(1.3, 1.3);
      }
    }
  }
`;
