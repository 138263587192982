import React from "react";
import { Link } from "react-router-dom";
import MetaData from "../Components/MetaData";

// animation importation
import Fade from "react-reveal/Fade";
import styled from "styled-components";

function NoMatch() {
  const Container = styled.main`
    background-image: url("/img/404.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 400px;
    width: 100vw;
    background-color: aliceblue;
    height: 100vh;
    @media (max-width: 548px) {
      background-size: 200px;
      background-position: center;
      align-items: center;
    }
    display: flex;
    justify-content: center;
    align-items: flex-end;
    h1 {
      text-align: center;
      margin-bottom: 100px;
      z-index: 200;
      @media (max-width: 548px) {
        margin-bottom: 0;
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  `;

  return (
    <>
      <MetaData title="Page Not Found " />
      <Fade>
        <Container>
          <h1>
            lost!
            <br />
            Wanna back to <Link to="/">Home</Link>!{" "}
          </h1>
        </Container>
        {/* <div className="lostpage backG">
          <img src="/notfound.webp" alt="" />
        </div> */}
      </Fade>
    </>
  );
}

export default NoMatch;
